<template>
    <v-list class="sidebar-wrapper">
        <template v-for="(item, index) in list">
            <template v-if="isInSideBar(item)">
                <v-list-item
                    link
                    v-if="!hasChildren(item)"
                    :to="item.path"
                    :key="`item-${index}`"
                    :ripple="true"
                    :disabled="item.meta.disabled"
                    @click="onItemClick(item)"
                >
                    <v-list-item-action>
                        <v-icon>{{ item.meta.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.meta.label || item.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.meta.sublabel">{{ item.meta.sublabel }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    v-else-if="hasChildren(item)"
                    :prepend-icon="item.meta.icon"
                    no-action="no-action"
                    :key="`item-${index}`"
                    :value="isExpanded(item)"
                >
                    <template v-slot:activator :ripple="true">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.meta.label || item.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="(subItem, subIndex) in item.children">
                        <v-list-item
                            v-if="isInSideBar(subItem)"
                            link
                            @click="onItemClick(item)"
                            :to="generatePath(item, subItem)"
                            :key="`item-${index}-${subIndex}`"
                            :ripple="true"
                        >
                            <v-list-item-action v-if="subItem.meta.icon">
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ subItem.meta && subItem.meta.label || subItem.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
            </template>
        </template>
    </v-list>
</template>

<script>
import isUndefined from 'lodash/isUndefined'
import { mapGetters, mapActions } from 'vuex'
import { GOAL_CLICK_MENU_ITEM, PARAM_MENU_ITEM } from '@/consts/metrika'

export default {
  mounted () {
    let route = this.$route
    if (route.name) {
      this.isReady = true
      this.shouldExpandMatchItem(route)
    }
  },
  computed: {
    ...mapGetters({
      menu: 'menuitems'
    }),
    list () {
      const { isMainTenant } = this.$store.state.app.config.tenant
      return this.menu.filter((item) => {
        return item.meta.onlyMainTenant ? isMainTenant : true
      })
    }
  },
  methods: {
    ...mapActions([
      'expandMenu'
    ]),
    onItemClick (e)  {
      return this.$metrika.reachGoal(GOAL_CLICK_MENU_ITEM, { [PARAM_MENU_ITEM]: e.name })
    },
    hasChildren (item) {
      if (!item.children) {
        return false
      }
      return item.children.filter(subItem => {
        return this.isInSideBar(subItem)
      }).length > 0
    },
    isExact (item) {
      isUndefined(item.meta.exact) || item.meta.exact
    },
    isExpanded (item) {
      return item.meta.expanded
    },
    checkRole (item) {
      if (!isUndefined(item.meta) && !isUndefined(item.meta.can)) {
        // TODO: if not string
        let splited = item.meta.can.split(' ')
        return this.$can(splited[0], splited[1])
      }
      if (!isUndefined(item.children) && item.children.length > 0) {
        for (let sitem in item.children) {
          if (this.checkRole(item.children[sitem])) {
            return true
          }
        }
        return false
      }

      return !isUndefined(item.path) && !isUndefined(item.component)
    },
    isInSideBar (item) {
      return (isUndefined(item.meta.inSideBar) || item.meta.inSideBar) && this.checkRole(item)
    },
    toggle (index, item) {
      this.expandMenu({
        index: index,
        expanded: !item.meta.expanded
      })
    },
    shouldExpandMatchItem (route) {
      let matched = route.matched
      let lastMatched = matched[matched.length - 1]
      let parent = lastMatched.parent || lastMatched
      const isParent = parent === lastMatched
      if (isParent) {
        const p = this.findParentFromMenu(route)
        if (p) {
          parent = p
        }
      }
      if ('expanded' in parent.meta && !isParent) {
        this.expandMenu({
          item: parent,
          expanded: true
        })
      }
    },
    generatePath (item, subItem) {
      return `${item.component ? item.path + '/' : ''}${subItem.path}`
    },
    findParentFromMenu (route) {
      const menu = this.menu
      for (let i = 0, l = menu.length; i < l; i++) {
        const item = menu[i]
        const k = item.children && item.children.length
        if (k) {
          for (let j = 0; j < k; j++) {
            if (item.children[j].name === route.name) {
              return item
            }
          }
        }
      }
    }
  },
  watch: {
    $route (route) {
      this.shouldExpandMatchItem(route)
    }
  }
}
</script>


<style>
  /*
  * Workaround for mobile safari
  * Padding allows to avoid overlapping last menu items by bottom panel
  */
  @media screen and (max-width: 479px) {
    .sidebar-wrapper {
      padding-bottom: 150px;
    }
  }
</style>
